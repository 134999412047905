import { useCallback } from 'react';
import {
  MasterPasswordResetRequestResponse,
  BulkApproveMasterPasswordResetRequestsResponse,
  BulkRejectMasterPasswordResetRequestsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { TGetAccountRecoveryRequestsMethod } from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const useMasterPasswordResetRequestsAPI = () => {
  const { masterPasswordResetRequestsAPIClient } = useApiClients();

  const getAccountRecoveryRequests = useCallback<
    TGetAccountRecoveryRequestsMethod
  >((params, axiosRequestConfig) => {
    const {
      page,
      pageLength,
      orderPropertyName = undefined,
      isDescending = undefined,
      searchQuery = undefined,
    } = params;
    return masterPasswordResetRequestsAPIClient.apiV1MasterPasswordResetRequestsGet(
      page,
      pageLength,
      orderPropertyName,
      isDescending,
      searchQuery,
      axiosRequestConfig,
    ).then(dataExtractor<MasterPasswordResetRequestResponse>());
  }, [masterPasswordResetRequestsAPIClient]);

  const approveAccountRecoveryRequest = useCallback(
    (masterPasswordResetRequestIds: string[]): Promise<
      BulkApproveMasterPasswordResetRequestsResponse
    > => masterPasswordResetRequestsAPIClient
      .apiV1MasterPasswordResetRequestsBulkApprovePost({ masterPasswordResetRequestIds })
      .then(dataExtractor<BulkApproveMasterPasswordResetRequestsResponse>()),
    [masterPasswordResetRequestsAPIClient],
  );

  const rejectAccountRecoveryRequest = useCallback(
    (masterPasswordResetRequestIds: string[]): Promise<
      BulkRejectMasterPasswordResetRequestsResponse
    > => masterPasswordResetRequestsAPIClient
      .apiV1MasterPasswordResetRequestsBulkRejectPost({ masterPasswordResetRequestIds })
      .then(dataExtractor<BulkRejectMasterPasswordResetRequestsResponse>()),
    [masterPasswordResetRequestsAPIClient],
  );
  return ({
    getAccountRecoveryRequests,
    approveAccountRecoveryRequest,
    rejectAccountRecoveryRequest,
  });
};
export default useMasterPasswordResetRequestsAPI;
