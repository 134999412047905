import { TUseQueryOptions, TUseQueryOptionsWithKey } from '@uniqkey-frontend/shared-app';
import {
  ApplicationGetByIdResponse,
  GetDomainRulesAndDesktopApplicationsByApplicationIdResponse,
  ApplicationGetResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQuery, type QueryKey } from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useApplicationsAPI from '../../useApplicationsAPI';
import { IGetServicesListParams } from '../../useApplicationsAPI/interfaces';
import { REACT_QUERY_SERVICES_KEY } from '../../tables/useServicesTable';

export const REACT_QUERY_SERVICE_KEY = [ReactQueryKeyEnum.Service];

export const REACT_QUERY_SERVICE_BY_APPLICATION_ID_KEY = [
  ReactQueryKeyEnum.ServicesByApplicationId,
];

interface IUseGetApplicationByIdParams {
  applicationId: string;
}

export const useGetApplicationById = (
  params: IUseGetApplicationByIdParams,
  options: TUseQueryOptions<ApplicationGetByIdResponse> = {},
) => {
  const { applicationId } = params;
  const { getApplicationById } = useApplicationsAPI();
  return useQuery<ApplicationGetByIdResponse>(
    (REACT_QUERY_SERVICE_KEY as QueryKey[]).concat([applicationId]),
    ({ signal }) => getApplicationById(applicationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetServicesByApplicationId = (
  params: IUseGetApplicationByIdParams,
  options: TUseQueryOptions<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse> = {},
) => {
  const { applicationId } = params;
  const { getServicesByApplicationId } = useApplicationsAPI();
  return useQuery<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse>(
    (REACT_QUERY_SERVICE_BY_APPLICATION_ID_KEY as QueryKey[]).concat([applicationId]),
    ({ signal }) => getServicesByApplicationId(applicationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetServices = (
  params: IGetServicesListParams,
  options: TUseQueryOptionsWithKey<ApplicationGetResponse> = {},
) => {
  const { getServicesList } = useApplicationsAPI();
  return useQuery<ApplicationGetResponse>(
    (REACT_QUERY_SERVICES_KEY as QueryKey[]).concat([params]),
    ({ signal }) => getServicesList(params, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
