/* eslint-disable max-len */
import { QueueEvent } from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../interfaces';
import handleProcessNewEmployeeEvent from './handleProcessNewEmployeeEvent';
import handleProcessInviteEmployeeEvent from './handleProcessInviteEmployeeEvent';
import handleShareGroupSecretWithEmployeeAccountEvent from './handleShareGroupSecretWithEmployeeAccountEvent';
import handleAdminVaultPasswordCreationForEmployeeRequestedEvent from './handleAdminVaultPasswordCreationForEmployeeRequestedEvent';
import handleAdminVaultPasswordCreationForGroupRequestedEvent from './handleAdminVaultPasswordCreationForGroupRequestedEvent';
import handleAdminVaultSecureNoteCreationForEmployeeRequestedEvent from './handleAdminVaultSecureNoteCreationForEmployeeRequestedEvent';
import handleAdminVaultSecureNoteCreationForGroupRequestedEvent from './handleAdminVaultSecureNoteCreationForGroupRequestedEvent';
import handleAdminVaultCreditCardCreationForEmployeeRequestedEvent from './handleAdminVaultCreditCardCreationForEmployeeRequestedEvent';
import handleAdminVaultCreditCardCreationForGroupRequestedEvent from './handleAdminVaultCreditCardCreationForGroupRequestedEvent';
import handleAdminVaultSharingToEmployeeAccountRequestedEvent from './handleAdminVaultSharingToEmployeeAccountRequestedEvent';
import handleAdminVaultSharingToGroupRequestedEvent from './handleAdminVaultSharingToGroupRequestedEvent';
import handlePromoteAdminEvent from './handlePromoteAdminEvent';
import handleRevokeAdminEvent from './handleRevokeAdminEvent';
import handleMoveVaultToPrivateKeychainEvent from './handleMoveVaultToPrivateKeychainEvent';
import handleVaultPasswordCreationRequestedEvent from './handleVaultPasswordCreationRequestedEvent';
import handleApproveMasterPasswordResetRequestEvent from './handleApproveMasterPasswordResetRequestEvent';
import handleVaultCreditCardCreationRequestedEvent
  from './handleVaultCreditCardCreationRequestedEvent';
import handleVaultSecureNoteCreationRequestedEvent
  from './handleVaultSecureNoteCreationRequestedEvent';
import handlePartnerSupporterRequestApprovalEvent
  from './handlePartnerSupporterRequestApprovalEvent';
import handleApproveMasterPasswordResetForMigratedEmployeeRequestEvent
  from './handleApproveMasterPasswordResetForMigratedEmployeeRequestEvent';

type IEventHandlersDictionary = {
  [key in QueueEvent]?: (params: ITrustedPortalHandlerParams) => Promise<void>;
}

const HANDLERS: IEventHandlersDictionary = {
  [QueueEvent.ProcessNewEmployee]: handleProcessNewEmployeeEvent,
  [QueueEvent.ProcessInviteEmployee]: handleProcessInviteEmployeeEvent,
  [QueueEvent.ShareGroupSecretWithEmployeeAccount]: handleShareGroupSecretWithEmployeeAccountEvent,
  [QueueEvent.AdminVaultPasswordCreationForEmployeeRequested]: handleAdminVaultPasswordCreationForEmployeeRequestedEvent,
  [QueueEvent.AdminVaultPasswordCreationForGroupRequested]: handleAdminVaultPasswordCreationForGroupRequestedEvent,
  [QueueEvent.AdminVaultSecureNoteCreationForEmployeeRequested]: handleAdminVaultSecureNoteCreationForEmployeeRequestedEvent,
  [QueueEvent.AdminVaultSecureNoteCreationForGroupRequested]: handleAdminVaultSecureNoteCreationForGroupRequestedEvent,
  [QueueEvent.AdminVaultCreditCardCreationForEmployeeRequested]: handleAdminVaultCreditCardCreationForEmployeeRequestedEvent,
  [QueueEvent.AdminVaultCreditCardCreationForGroupRequested]: handleAdminVaultCreditCardCreationForGroupRequestedEvent,
  [QueueEvent.AdminVaultSharingToEmployeeAccountRequested]: handleAdminVaultSharingToEmployeeAccountRequestedEvent,
  [QueueEvent.AdminVaultSharingToGroupRequested]: handleAdminVaultSharingToGroupRequestedEvent,
  [QueueEvent.PromoteAdmin]: handlePromoteAdminEvent,
  [QueueEvent.RevokeAdmin]: handleRevokeAdminEvent,
  [QueueEvent.MoveVaultToPrivateKeychain]: handleMoveVaultToPrivateKeychainEvent,
  [QueueEvent.VaultPasswordCreationRequested]: handleVaultPasswordCreationRequestedEvent,
  [QueueEvent.VaultCreditCardCreationRequested]: handleVaultCreditCardCreationRequestedEvent,
  [QueueEvent.VaultSecureNoteCreationRequested]: handleVaultSecureNoteCreationRequestedEvent,
  [QueueEvent.ApproveMasterPasswordResetRequest]: handleApproveMasterPasswordResetRequestEvent,
  [QueueEvent.PartnerSupporterRequestApproval]: handlePartnerSupporterRequestApprovalEvent,
  [QueueEvent.ApproveMasterPasswordResetForMigratedEmployeeRequest]: handleApproveMasterPasswordResetForMigratedEmployeeRequestEvent,
};

const getHandler = (queueEvent: QueueEvent) => HANDLERS[queueEvent];

export default {
  getHandler,
};
