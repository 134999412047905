import { useCallback } from 'react';
import {
  GetVaultPasswordsResponse,
  GetVaultPasswordByIdResponse,
  GetVaultSecureNotesResponse,
  CreateVaultPasswordRequest,
  OkResult,
  DetachBulkVaultsRequest,
  DetachBulkVaultsResponse,
  DeleteBulkVaultsRequest,
  DeleteBulkVaultsResponse,
  MoveToPrivateKeychainBulkRequest,
  MoveToPrivateKeychainBulkResponse,
  GetVaultCreditCardsResponse,
  CreateVaultSecureNoteRequest,
  CreateVaultCreditCardRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { AxiosRequestConfig } from 'axios';
import { useApiClients } from '../../contexts/APIClientsContext';
import {
  TGetVaultPasswordsMethod,
  TGetVaultSecureNotesMethod,
  TGetVaultCreditCardsMethod,
} from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const useVaultsAPI = () => {
  const { vaultsAPIClient } = useApiClients();

  const getVaultPasswords = useCallback<TGetVaultPasswordsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        employeeAccountId = undefined,
        searchQuery = undefined,
        ownership = undefined,
        applicationName = undefined,
        minStrength = undefined,
        maxStrength = undefined,
        minSecurityScore = undefined,
        maxSecurityScore = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        password2FAStatus = undefined,
        isReusedByUser = undefined,
        isReusedByOrganization = undefined,
      } = params;
      return vaultsAPIClient.apiV1VaultsGetVaultPasswordsGet(
        page,
        pageLength,
        groupId,
        employeeAccountId,
        searchQuery,
        ownership,
        applicationName,
        minStrength,
        maxStrength,
        minSecurityScore,
        maxSecurityScore,
        orderPropertyName,
        isDescending,
        undefined, // TODO: remove undefined
        password2FAStatus,
        isReusedByUser,
        isReusedByOrganization,
        axiosRequestConfig,
      ).then(dataExtractor<GetVaultPasswordsResponse>());
    },
    [vaultsAPIClient],
  );

  const getVaultPasswordById = useCallback((vaultId: string, options?: AxiosRequestConfig)
    :Promise<GetVaultPasswordByIdResponse> => vaultsAPIClient
    .apiV1VaultsGetVaultPasswordByIdVaultIdGet(vaultId, options)
    .then(dataExtractor<GetVaultPasswordByIdResponse>()), [vaultsAPIClient]);

  const getVaultSecureNotes = useCallback<TGetVaultSecureNotesMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        employeeAccountId = undefined,
        searchQuery = undefined,
        ownership = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return vaultsAPIClient.apiV1VaultsGetVaultSecureNotesGet(
        page,
        pageLength,
        groupId,
        employeeAccountId,
        searchQuery,
        ownership,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetVaultSecureNotesResponse>());
    },
    [vaultsAPIClient],
  );

  const createVaultPasswords = useCallback(
    (createVaultPasswordRequest: CreateVaultPasswordRequest, options?: AxiosRequestConfig)
      :Promise<OkResult> => vaultsAPIClient
      .apiV1VaultsCreateVaultPasswordPost(createVaultPasswordRequest, options)
      .then(dataExtractor<OkResult>()),
    [vaultsAPIClient],
  );

  const detachVaults = useCallback(
    (detachBulkVaultsRequest: DetachBulkVaultsRequest, options?: AxiosRequestConfig)
      :Promise<DetachBulkVaultsResponse> => vaultsAPIClient
      .apiV1VaultsDetachBulkPost(detachBulkVaultsRequest, options)
      .then(dataExtractor<DetachBulkVaultsResponse>()),
    [vaultsAPIClient],
  );

  const deleteVaults = useCallback(
    (deleteBulkVaultsRequest: DeleteBulkVaultsRequest, options?: AxiosRequestConfig)
      :Promise<DeleteBulkVaultsResponse> => vaultsAPIClient
      .apiV1VaultsDeleteBulkPost(deleteBulkVaultsRequest, options)
      .then(dataExtractor<DeleteBulkVaultsResponse>()),
    [vaultsAPIClient],
  );

  const moveVaultsToPrivateKeychain = useCallback(
    (
      moveToPrivateKeychainBulkRequest: MoveToPrivateKeychainBulkRequest,
      options?: AxiosRequestConfig,
    ): Promise<MoveToPrivateKeychainBulkResponse> => vaultsAPIClient
      .apiV1VaultsMoveToPrivateKeychainBulkPost(
        moveToPrivateKeychainBulkRequest,
        options,
      )
      .then(dataExtractor<MoveToPrivateKeychainBulkResponse>()),
    [vaultsAPIClient],
  );

  const getVaultCreditCards = useCallback<TGetVaultCreditCardsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        employeeAccountId = undefined,
        searchQuery = undefined,
        ownership = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return vaultsAPIClient.apiV1VaultsGetVaultCreditCardsGet(
        page,
        pageLength,
        groupId,
        employeeAccountId,
        searchQuery,
        ownership,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetVaultCreditCardsResponse>());
    },
    [vaultsAPIClient],
  );

  const createVaultSecureNote = useCallback(
    (createVaultSecureNoteRequest: CreateVaultSecureNoteRequest, options?: AxiosRequestConfig)
      :Promise<OkResult> => vaultsAPIClient
      .apiV1VaultsCreateVaultSecureNotePost(createVaultSecureNoteRequest, options)
      .then(dataExtractor<OkResult>()),
    [vaultsAPIClient],
  );

  const createVaultCreditCard = useCallback(
    (createVaultCreditCardRequest: CreateVaultCreditCardRequest, options?: AxiosRequestConfig)
      : Promise<OkResult> => vaultsAPIClient
      .apiV1VaultsCreateVaultCreditCardPost(createVaultCreditCardRequest, options)
      .then(dataExtractor<OkResult>()),
    [vaultsAPIClient],
  );

  return ({
    getVaultPasswords,
    getVaultPasswordById,
    getVaultSecureNotes,
    createVaultPasswords,
    detachVaults,
    deleteVaults,
    moveVaultsToPrivateKeychain,
    getVaultCreditCards,
    createVaultSecureNote,
    createVaultCreditCard,
  });
};

export default useVaultsAPI;
