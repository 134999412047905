import { memo } from 'react';
import {
  useGetEmployeeAverageSecurityScore,
} from '../../../../hooks/reactQuery';
import SecurityScoreWidget from '../../../../components/SecurityScoreWidget';

interface IEmployeeDetailsSecurityScoreWidgetProps {
  employeeAccountId: string;
}

const EmployeeDetailsSecurityScoreWidget = (props: IEmployeeDetailsSecurityScoreWidgetProps) => {
  const { employeeAccountId } = props;
  const {
    data, isLoading, isError,
  } = useGetEmployeeAverageSecurityScore({ employeeAccountId });
  const { averageSecurityScore } = data ?? {};

  if (isError) {
    return null;
  }

  return <SecurityScoreWidget isLoading={isLoading} percentage={averageSecurityScore} />;
};

export default memo(EmployeeDetailsSecurityScoreWidget);
