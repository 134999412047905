import {
  useQuery, useMutation, useQueryClient, type QueryKey,
} from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountType,
  GetEmployeeAccountByIdResponse,
  Operation,
  NoContentResult,
  DeleteBulkEmployeeAccountResponse,
  DeleteBulkEmployeeAccountRequest,
  GetAverageSecurityScoreResponse,
  MeResponse,
  PromoteAdminResponse,
  RevokeAdminResponse,
  GetUnprocessedSCIMUsersCountResponse,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IReplacePatchOperation, parseReplacePatchOperation } from '../../../helpers/apiClients';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';

export const REACT_QUERY_CURRENT_EMPLOYEE_KEY = [ReactQueryKeyEnum.CurrentEmployee];

export const REACT_QUERY_EMPLOYEE_KEY = [ReactQueryKeyEnum.Employee];

export const REACT_QUERY_EMPLOYEE_SECURITY_SCORE_KEY = [ReactQueryKeyEnum.EmployeeSecurityScore];

export const REACT_QUERY_UNPROCESSED_SCIM_USERS_COUNT_KEY = [
  ReactQueryKeyEnum.UnprocessedSCIMUsersCount,
];

export const useGetCurrentEmployee = (
  options: TUseQueryOptions<MeResponse> = {},
) => {
  const { getCurrentEmployee } = useEmployeeAccountsAPI();
  return useQuery<MeResponse>(
    REACT_QUERY_CURRENT_EMPLOYEE_KEY,
    ({ signal }) => getCurrentEmployee({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetEmployeeAccountByIdParams {
  employeeAccountId: string;
}
export const useGetEmployeeAccountById = (
  params: IUseGetEmployeeAccountByIdParams,
  options: TUseQueryOptions<GetEmployeeAccountByIdResponse> = {},
) => {
  const { employeeAccountId } = params;
  const { getEmployeeAccountById } = useEmployeeAccountsAPI();
  return useQuery<GetEmployeeAccountByIdResponse>(
    (REACT_QUERY_EMPLOYEE_KEY as QueryKey[]).concat([employeeAccountId]),
    ({ signal }) => getEmployeeAccountById(employeeAccountId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUsePatchEmployeeAccountByIdParams {
  employeeAccountId: string;
  useOptimisticUpdates?: boolean;
}
export const usePatchEmployeeAccountById = (
  params: IUsePatchEmployeeAccountByIdParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Operation[],
    TUseMutationContext<GetEmployeeAccountByIdResponse>
  > = {},
) => {
  const { employeeAccountId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { patchEmployeeAccountById } = useEmployeeAccountsAPI();
  const mutationKey = (REACT_QUERY_EMPLOYEE_KEY as QueryKey[]).concat([employeeAccountId]);
  return useMutation(
    mutationKey,
    (operations) => patchEmployeeAccountById(employeeAccountId, operations),
    {
      onMutate: async (operations) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        const updatedParts = operations.reduce((acc, operation) => ({
          ...acc,
          ...parseReplacePatchOperation(operation as IReplacePatchOperation),
        }), {});
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<GetEmployeeAccountByIdResponse>(mutationKey);
        queryClient.setQueryData<GetEmployeeAccountByIdResponse>(
          mutationKey,
          (oldEmployee) => ({
            ...oldEmployee,
            ...updatedParts as GetEmployeeAccountByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetEmployeeAccountByIdResponse };
      },
      onError: (err, employee, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetEmployeeAccountByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      ...options,
    },
  );
};

interface IUseChangeAdminRightsParams {
  employeeAccountId: string;
  promote: boolean;
  useOptimisticUpdates?: boolean;
}
export const useChangeAdminRights = (
  params: IUseChangeAdminRightsParams,
  options: TUseMutationOptions<
    PromoteAdminResponse | RevokeAdminResponse,
    unknown,
    undefined | void,
    TUseMutationContext<GetEmployeeAccountByIdResponse>
  > = {},
) => {
  const { employeeAccountId, promote, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { promoteAdmin, revokeAdmin } = useEmployeeAccountsAPI();
  const action = promote ? promoteAdmin : revokeAdmin;
  const mutationKey = (REACT_QUERY_EMPLOYEE_KEY as QueryKey[]).concat([employeeAccountId]);
  return useMutation(mutationKey, () => action(employeeAccountId), {
    onMutate: async () => {
      if (!useOptimisticUpdates) {
        return null;
      }
      await queryClient.cancelQueries(mutationKey);
      const previousValue = queryClient.getQueryData<GetEmployeeAccountByIdResponse>(mutationKey);
      queryClient.setQueryData<GetEmployeeAccountByIdResponse>(mutationKey, (oldEmployee) => ({
        ...oldEmployee as GetEmployeeAccountByIdResponse,
        employeeAccountType: promote
          ? EmployeeAccountType.Admin
          : EmployeeAccountType.Employee,
      }));
      return { previousValue: previousValue as GetEmployeeAccountByIdResponse };
    },
    onError: (err, variables, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<
          GetEmployeeAccountByIdResponse
        >(mutationKey, context.previousValue);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(mutationKey);
    },
    ...options,
  });
};

export const useDeleteEmployeeAccounts = (
  options: TUseMutationOptions<
    DeleteBulkEmployeeAccountResponse,
    unknown,
    DeleteBulkEmployeeAccountRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { deleteEmployeeAccounts } = useEmployeeAccountsAPI();
  return useMutation(
    (deleteBulkEmployeeAccountsRequest) => deleteEmployeeAccounts(
      deleteBulkEmployeeAccountsRequest,
    ),
    {
      onSettled: (data, error, variables) => {
        variables.employeeAccountIds.forEach((employeeAccountId) => {
          queryClient.removeQueries(
            (REACT_QUERY_EMPLOYEE_KEY as QueryKey[]).concat([employeeAccountId]),
          );
        });
      },
      ...options,
    },
  );
};

interface IUseGetEmployeeAverageSecurityScoreParams {
  employeeAccountId: string;
}
export const useGetEmployeeAverageSecurityScore = (
  params: IUseGetEmployeeAverageSecurityScoreParams,
  options: TUseQueryOptions<GetAverageSecurityScoreResponse> = {},
) => {
  const { employeeAccountId } = params;
  const { getAverageSecurityScore } = useEmployeeAccountsAPI();
  return useQuery<GetAverageSecurityScoreResponse>(
    (REACT_QUERY_EMPLOYEE_SECURITY_SCORE_KEY as QueryKey[]).concat([employeeAccountId]),
    ({ signal }) => getAverageSecurityScore(employeeAccountId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetUnprocessedSCIMUsersCount = (
  options: TUseQueryOptions<GetUnprocessedSCIMUsersCountResponse> = {},
) => {
  const { getUnprocessedSCIMUsersCount } = useEmployeeAccountsAPI();
  return useQuery<GetUnprocessedSCIMUsersCountResponse>(
    REACT_QUERY_UNPROCESSED_SCIM_USERS_COUNT_KEY,
    ({ signal }) => getUnprocessedSCIMUsersCount({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
