import { useCallback } from 'react';
import {
  GetPartnerUsersAccessibleToCurrentOrganizationResponse,
  GetPartnersAccessibleToCurrentOrganizationResponse,
  BulkRevokePartnerSupportContractsRequest,
  BulkRevokePartnerSupportContractsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { AxiosRequestConfig } from 'axios';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import { TGetPartnerUsersAccessMethod, TGetPartnersAccessMethod } from './interfaces';

const useOrganizationSettingsAPI = () => {
  const { organizationSettingsAPIClient } = useApiClients();

  const getPartnerUsersAccess = useCallback<
    TGetPartnerUsersAccessMethod
  >(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return organizationSettingsAPIClient.apiV1OrganizationSettingsPartnerSupportAccessGet(
        page,
        pageLength,
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetPartnerUsersAccessibleToCurrentOrganizationResponse>());
    },
    [organizationSettingsAPIClient],
  );

  const getPartnersAccess = useCallback<TGetPartnersAccessMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return organizationSettingsAPIClient.apiV1OrganizationSettingsPartnerSupportContractsGet(
        page,
        pageLength,
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetPartnersAccessibleToCurrentOrganizationResponse>());
    },
    [organizationSettingsAPIClient],
  );

  const revokePartnersAccess = useCallback(
    (
      bulkRevokePartnerSupportContractsRequest?: BulkRevokePartnerSupportContractsRequest,
      options?: AxiosRequestConfig,
    ): Promise<BulkRevokePartnerSupportContractsResponse> => organizationSettingsAPIClient
      .apiV1OrganizationSettingsPartnerSupportContractsRevokePost(
        bulkRevokePartnerSupportContractsRequest,
        options,
      )
      .then(dataExtractor<BulkRevokePartnerSupportContractsResponse>()),
    [organizationSettingsAPIClient],
  );

  return ({
    getPartnerUsersAccess,
    getPartnersAccess,
    revokePartnersAccess,
  });
};

export default useOrganizationSettingsAPI;
