import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import {
  GetEmployeeAccountsOnBoardResponse,
  GetEmployeeActivationRateResponse,
  GetActiveEmployeesRateResponse,
  GetAverageSecurityScoreForWidgetResponse,
  GetSecurityScoreRateResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQuery, type QueryKey } from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useDashboardAPI from '../../useDashboardAPI';

export const REACT_QUERY_DASHBOARD_EMPLOYEE_ACTIVATION_RATE_KEY = [
  ReactQueryKeyEnum.DashboardEmployeeActivationRate,
];

export const REACT_QUERY_DASHBOARD_ACTIVE_EMPLOYEES_RATE_KEY = [
  ReactQueryKeyEnum.DashboardActiveEmployeesRate,
];

export const REACT_QUERY_DASHBOARD_AVERAGE_SECURITY_SCORE_KEY = [
  ReactQueryKeyEnum.DashboardAverageSecurityScore,
];

export const REACT_QUERY_DASHBOARD_SECURITY_SCORE_RATE_KEY = [
  ReactQueryKeyEnum.DashboardSecurityScoreRate,
];

export const REACT_QUERY_DASHBOARD_EMPLOYEE_ACCOUNTS_ON_BOARD_KEY = [
  ReactQueryKeyEnum.DashboardEmployeeAccountsOnBoard,
];

export const useGetEmployeeAccountsOnBoard = (
  options: TUseQueryOptions<GetEmployeeAccountsOnBoardResponse> = {},
) => {
  const { getEmployeeAccountsOnBoard } = useDashboardAPI();
  return useQuery<GetEmployeeAccountsOnBoardResponse>(
    REACT_QUERY_DASHBOARD_EMPLOYEE_ACCOUNTS_ON_BOARD_KEY,
    ({ signal }) => getEmployeeAccountsOnBoard({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetEmployeeActivationRateParams {
  from: string;
  to: string;
}
export const useGetEmployeeActivationRate = (
  params: IUseGetEmployeeActivationRateParams,
  options: TUseQueryOptions<GetEmployeeActivationRateResponse> = {},
) => {
  const { from, to } = params;
  const { getEmployeeActivationRate } = useDashboardAPI();
  return useQuery<GetEmployeeActivationRateResponse>(
    (REACT_QUERY_DASHBOARD_EMPLOYEE_ACTIVATION_RATE_KEY as QueryKey[]).concat([`${from}--${to}`]),
    ({ signal }) => getEmployeeActivationRate(from, to, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetActiveEmployeesRateParams {
  from: string;
  to: string;
}
export const useGetActiveEmployeesRate = (
  params: IUseGetActiveEmployeesRateParams,
  options: TUseQueryOptions<GetActiveEmployeesRateResponse> = {},
) => {
  const { from, to } = params;
  const { getActiveEmployeesRate } = useDashboardAPI();
  return useQuery<GetActiveEmployeesRateResponse>(
    (REACT_QUERY_DASHBOARD_ACTIVE_EMPLOYEES_RATE_KEY as QueryKey[]).concat([`${from}--${to}`]),
    ({ signal }) => getActiveEmployeesRate(from, to, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetAverageSecurityScore = (
  options: TUseQueryOptions<GetAverageSecurityScoreForWidgetResponse> = {},
) => {
  const { getAverageSecurityScore } = useDashboardAPI();
  return useQuery<GetAverageSecurityScoreForWidgetResponse>(
    REACT_QUERY_DASHBOARD_AVERAGE_SECURITY_SCORE_KEY,
    ({ signal }) => getAverageSecurityScore({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetSecurityScoreRateParams {
  from: string;
  to: string;
}
export const useGetSecurityScoreRate = (
  params: IUseGetSecurityScoreRateParams,
  options: TUseQueryOptions<GetSecurityScoreRateResponse> = {},
) => {
  const { from, to } = params;
  const { getSecurityScoreRate } = useDashboardAPI();
  return useQuery<GetSecurityScoreRateResponse>(
    (REACT_QUERY_DASHBOARD_SECURITY_SCORE_RATE_KEY as QueryKey[]).concat([`${from}--${to}`]),
    ({ signal }) => getSecurityScoreRate(from, to, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
