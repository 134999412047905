import { memo } from 'react';
import {
  AR1, Box, Grid, SecurityScoreEnum, SecurityScoreIcon, Tooltip, useTranslations, WarningIcon,
} from '@uniqkey-frontend/shared-app';
import { isNumber } from 'lodash';

interface IColumnWithSecurityScoreParams {
  strength?: number | null;
  isReusedByUser?: boolean;
  isReusedByOrganization?: boolean;
}

interface IGetTooltipTextProps {
  isReusedByOrganization?: boolean;
  isReusedByUser?: boolean;
  isPasswordWeak?: boolean;
}

const getTooltipTitleText = (props: IGetTooltipTextProps) => {
  const { isReusedByOrganization, isReusedByUser, isPasswordWeak } = props;
  if (isReusedByOrganization) {
    return 'reusedPassword.tooltipText.isReusedByOrganization';
  }
  if (isReusedByUser) {
    return 'reusedPassword.tooltipText.isReusedByUser';
  }
  if (isPasswordWeak) {
    return 'reusedPassword.tooltipText.isPasswordWeak';
  }
  return '';
};

const ColumnWithSecurityScore = (params: IColumnWithSecurityScoreParams) => {
  const { strength, isReusedByUser, isReusedByOrganization } = params;
  const { t } = useTranslations();

  if (!isNumber(strength)) {
    return t('common.noScore');
  }

  const isPasswordWeak = strength! <= SecurityScoreEnum.Weak;
  const isNotValid = isReusedByUser || isReusedByOrganization || isPasswordWeak;
  const tooltipTitleText = getTooltipTitleText({
    isReusedByOrganization, isReusedByUser, isPasswordWeak,
  });

  return (
    <Grid container>
      <SecurityScoreIcon percentage={strength} />
      <Box ml={2} />
      {isNotValid && (
        <Tooltip title={t(tooltipTitleText)} cursorPointer>
          <WarningIcon width={24} height={24} color={AR1} />
        </Tooltip>
      )}
    </Grid>
  );
};

export default memo(ColumnWithSecurityScore);
