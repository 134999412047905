import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  getTimeFromNow,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  GetManagedByGroupsByVaultIdResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import {
  TGetEmployeesManagedByGroupsByVaultIdMethod,
} from '../../../hooks/useEmployeeAccountsAPI/interfaces';

// eslint-disable-next-line max-len
export interface IOrganizationLoginEmployeesToGroupsTableRow extends GetManagedByGroupsByVaultIdResponseModel {
  managedBy: string;
  lastActivity: string | null;
}

interface IOrganizationLoginEmployeesToGroupsTableProps extends
  Omit<
    IUseTableReturn<IOrganizationLoginEmployeesToGroupsTableRow>,
    'selectedRows' | 'resetTableMethods'
  >,
  Omit<
    IUseQueryTableFetchReturn<TGetEmployeesManagedByGroupsByVaultIdMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  employees: IUseQueryTableFetchReturn<TGetEmployeesManagedByGroupsByVaultIdMethod>['data'];
  selectedEmployees: IUseTableReturn<IOrganizationLoginEmployeesToGroupsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationLoginEmployeesToGroupsTableRow> = {
  selection: false,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const OrganizationLoginEmployeesToGroupsTable = (
  props: IOrganizationLoginEmployeesToGroupsTableProps,
) => {
  const {
    employees: preEmployees,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedEmployees,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const employees = useMemo(() => preEmployees.map((employee) => {
    const groupsList = employee.groupsInformation
      .map((groupInformation) => groupInformation.name)
      .join(', ');
    return {
      ...employee,
      managedBy: t(
        'organizationLoginPage.employeesToGroupsTab.table.managedByGroup',
        { groupsList },
      ),
      lastActivity: getTimeFromNow({
        date: employee.activityAt,
        locale: currentLanguage,
      }),
    };
  }), [preEmployees, currentLanguage, t]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationLoginPage.employeesToGroupsTab.table.columns.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '30%',
    },
    {
      title: t('organizationLoginPage.employeesToGroupsTab.table.columns.managedBy'),
      field: 'managedBy',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.managedBy}
        </TypographyWithTooltip>
      ),
      width: '45%',
      sorting: false,
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t(
            'organizationLoginPage.employeesToGroupsTab.table.columns.lastActivity.columnTooltip',
          )}
        >
          {t('organizationLoginPage.employeesToGroupsTab.table.columns.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
  ]), [createColumns, t]);

  const handleEmployeeRowClick = useCallback<
    NonNullable<IMaterialTableProps<IOrganizationLoginEmployeesToGroupsTableRow>['onRowClick']>
  >((event, employee) => {
    navigate(`${PageRouteEnum.Employees}/${employee!.employeeAccountId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={employees}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedEmployees}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleEmployeeRowClick}
      rowSelectByKey="employeeAccountId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationLoginEmployeesToGroupsTable);
