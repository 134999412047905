import {
  useCallback, memo, useState,
} from 'react';
import { useSnackbar, useTranslations } from '@uniqkey-frontend/shared-app';
import {
  GetEmployeeAccountByIdResponse,
  GetVaultCreditCardsResponseModel,
  GetVaultPasswordsResponseModel,
  GetVaultSecureNotesResponseModel,
  GetForLookupResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import EntitySelectorModal, { TEntitySelectorModalOnSubmit } from '../EntitySelectorModal';
import useEmployeeAccountsAPI from '../../hooks/useEmployeeAccountsAPI';
import useVaultsToEmployeeAccountsAPI from '../../hooks/useVaultsToEmployeeAccountsAPI';
import VaultTypeEnum from '../../enums/VaultTypeEnum';
import VaultTypeNameEnum from '../../enums/VaultTypeNameEnum';
import { logException } from '../../services/sentryService';
import { getActiveOrganizationId } from '../../services/organizationService';
import { useTrustedPortalStore } from '../../modules/TrustedPortalModule/store';

interface IShareVaultsToEmployeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  vaults: GetVaultPasswordsResponseModel[] | GetVaultSecureNotesResponseModel[]
    | GetVaultCreditCardsResponseModel[];
  onSubmit?: () => void;
  type: VaultTypeEnum;
}

interface IParsedEntity {
  id: GetEmployeeAccountByIdResponse['employeeAccountId'];
  label: GetEmployeeAccountByIdResponse['email'];
}

const ShareVaultsToEmployeeModal = (props: IShareVaultsToEmployeeModalProps) => {
  const {
    isOpen, onClose, vaults, onSubmit, type,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadedEmployeeAccountsCount, setLoadedEmployeeAccountsCount] = useState<number>(0);
  const [
    totalLoadedEmployeeAccountsCount, setTotalLoadedEmployeeAccountsCount,
  ] = useState<number>(0);
  const [response, setResponse] = useState<GetForLookupResponseModel[]>([]);
  const { t } = useTranslations();
  const { showWarning, showError, showSuccess } = useSnackbar();
  const { getEmployeeAccountsForLookup } = useEmployeeAccountsAPI();
  const { shareVaultsToEmployee } = useVaultsToEmployeeAccountsAPI();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const {
        data,
        total,
      } = await getEmployeeAccountsForLookup({ page: 1, pageLength: 200, searchQuery });
      setLoadedEmployeeAccountsCount(data.length);
      setTotalLoadedEmployeeAccountsCount(total);
      setResponse(data);
      return data;
    } catch (e) {
      setLoadedEmployeeAccountsCount(0);
      setTotalLoadedEmployeeAccountsCount(0);
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'ShareVaultsToEmployeeModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getEmployeeAccountsForLookup, showError, t]);

  const vaultType = VaultTypeNameEnum[VaultTypeEnum[type] as keyof typeof VaultTypeEnum];

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity>
  >(async (value) => {
    try {
      setIsLoading(true);
      const requests = vaults.map((vault) => (
        { vaultId: vault.vaultId, employeeAccountId: value.id }
      ));
      await shareVaultsToEmployee({ requests });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t(`trustedPortalSuccessNotifications.${vaultType}.shared`),
        });
      } else {
        showWarning({
          text: t('shareEntityToEmployeeModal.approvalOnMobileToast'),
        });
      }
      if (onSubmit) {
        onSubmit();
      }
      setIsLoading(false);
      onClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'ShareVaultsToEmployeeModal/handleSubmitEntitySelectorModal exception',
      });
      setIsLoading(false);
    }
  }, [
    shareVaultsToEmployee,
    vaults,
    onSubmit,
    onClose,
    vaultType,
    isTrustedPortalEnabled,
    showWarning,
    showError,
    showSuccess,
    t,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (employeeAccountsToParse: GetEmployeeAccountByIdResponse[]) => employeeAccountsToParse.map(
      (employeeAccount: GetEmployeeAccountByIdResponse) => ({
        id: employeeAccount.employeeAccountId,
        label: employeeAccount.email,
      }),
    ),
    [],
  );

  return (
    <EntitySelectorModal
      data={response}
      dataKey="employeeAccountId"
      isOpen={isOpen}
      title={t(`shareEntityToEmployeeModal.${vaultType}.title`)}
      dialogContentText={t(`shareEntityToEmployeeModal.${vaultType}.description`)}
      placeholder={t('shareEntityToEmployeeModal.placeholder')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isLoading}
      multiple={false}
      submitButtonText="common.share"
      loadedOptionsCount={loadedEmployeeAccountsCount}
      totalLoadedOptionsCount={totalLoadedEmployeeAccountsCount}
    />
  );
};

export default memo(ShareVaultsToEmployeeModal);
