// Queue Event ID -> translation suffix
const AuditLogQueueEventsNames = {
  2: 'loginCredentialsRequested',
  3: 'processNewEmployee',
  4: 'deleteVaultForGroupRequested',
  5: 'editLoginRequested',
  6: 'createLoginForGroupRequested',
  7: 'createLoginForEmployeeRequested',
  8: 'deleteLoginForEmployeeRequested',
  9: 'promoteToAdmin',
  10: 'revokeAdmin',
  11: 'importVault',
  12: 'shareVaultToGroupRequested',
  13: 'shareVaultToEmployeeRequested',
  14: 'logoutDevice',
  15: 'createTrustedBrowserRequested',
  16: 'processEmployeeInvite',
  17: 'shareGroupSecretWithEmployee',
  18: 'moveVaultBetweenGroups',
  19: 'moveVaultBetweenEmployees',
  20: 'moveVaultFromEmployeeToGroup',
  21: 'createLoginForGroupRequestedByAdmin',
  22: 'createLoginForEmployeeRequestedByAdmin',
  23: 'shareVaultToGroupRequestedByAdmin',
  24: 'shareVaultToEmployeeRequestedByAdmin',
  25: 'loginCredentialsForGroupRequested',
  26: 'getLoginCredentialsWithTrustedBrowserRequested',
  27: 'createSecureNoteForGroupRequestedByAdmin',
  28: 'createSecureNoteForEmployeeRequestedByAdmin',
  29: 'editSecureNoteRequested',
  30: 'secureNoteRequested',
  31: 'secureNoteForGroupRequested',
  32: 'createLoginRequested',
  33: 'getLoginCredentialsForGroupWithTrustedBrowserRequested',
  34: 'createSecureNoteForGroupRequested',
  35: 'createSecureNoteForEmployeeRequested',
  36: 'editSecureNoteForGroupRequested',
  37: 'editLoginForGroupRequested',
  38: 'approvedPromoteAdminRights',
  39: 'approvedRevokeAdminRights',
  40: 'approveMasterPasswordResetRequested',
  62: 'rejectedApprovedTrustedPortal',
  67: 'rejectedApprovedTrustBrowser',
  68: 'rejectedApprovedImportOfLogins',
  69: 'rejectedApprovedExportOfLogins',
  72: 'checkGeolocationRestrictions',
};

export default AuditLogQueueEventsNames;
