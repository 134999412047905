import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseTableReturn,
  SecurityScoreIcon,
  Table,
  Grid,
  LoginAvatar,
  useTranslations,
  getTimeFromNow,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import { ApplicationGetInfo } from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { TGetServicesListMethod } from '../../../hooks/useApplicationsAPI/interfaces';

export interface IServicesTableRow extends ApplicationGetInfo {
  lastActivity: string | null;
}

interface IServicesTableProps extends
  Omit<IUseTableReturn<IServicesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetServicesListMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  services: IUseQueryTableFetchReturn<TGetServicesListMethod>['data'];
  selectedServices: IUseTableReturn<IServicesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IServicesTableRow> = {
  selection: false,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const ServicesTable = (props: IServicesTableProps) => {
  const {
    services: preServices,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedServices,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const services = useMemo(() => preServices.map((service) => ({
    ...service,
    lastActivity: getTimeFromNow({
      date: service.activityAt,
      locale: currentLanguage,
    }),
  })), [preServices, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('servicesPage.service'),
      field: 'name',
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <LoginAvatar src={rowData.iconUrl} />
          <TypographyWithTooltip variant="body2">
            {rowData.name}
          </TypographyWithTooltip>
        </Grid>
      ),
      width: '25%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('servicesPage.totalEmployees.columnTooltip')}
        >
          {t('servicesPage.totalEmployees')}
        </TypographyWithTooltip>
      ),
      field: 'userCount',
      render: (rowData) => {
        const title = rowData.userCount > 0 ? t('servicesPage.totalEmployeesCellTitle') : '';
        return (
          <TypographyWithTooltip variant="body2" title={title}>
            {rowData.userCount}
          </TypographyWithTooltip>
        );
      },
      width: '15%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('servicesPage.totalLogins.columnTooltip')}
        >
          {t('servicesPage.totalLogins')}
        </TypographyWithTooltip>
      ),
      field: 'vaultCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2" title={t('servicesPage.totalLoginsCellTitle')}>
          {rowData.vaultCount}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('servicesPage.securityScore'),
      field: 'securityScore',
      render: (rowData) => (
        <SecurityScoreIcon percentage={rowData.securityScore} />
      ),
      width: '20%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('servicesPage.lastActivity.columnTooltip')}
        >
          {t('servicesPage.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
  ]), [createColumns, t]);

  const handleServiceRowClick = useCallback<
    NonNullable<IMaterialTableProps<IServicesTableRow>['onRowClick']>
  >((event, service) => {
    const { userCount, vaultCount, applicationId } = service!;
    if (userCount > 0 || vaultCount > 0) {
      navigate(`${PageRouteEnum.Services}/${applicationId}`);
    }
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={services}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedServices}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleServiceRowClick}
      rowSelectByKey="applicationId"
      rowHeight={51}
    />
  );
};

export default memo(ServicesTable);
