import { memo } from 'react';
import {
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { Ownership } from '@uniqkey-backend-organization-web/api-client';
import SelectionModal from '../SelectionModal';
import SelectionModalOption, { TSelectionOptionType } from '../SelectionModalOption';
import VaultTypeEnum from '../../enums/VaultTypeEnum';
import VaultTypeNameEnum from '../../enums/VaultTypeNameEnum';

interface IShareVaultsToEmployeeOrGroupSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (type: TSelectionOptionType) => void;
  type: VaultTypeEnum;
}

const ShareVaultsToEmployeeOrGroupSelectionModal = (
  props: IShareVaultsToEmployeeOrGroupSelectionModalProps,
) => {
  const {
    isOpen, onClose, onSelect, type,
  } = props;
  const { t } = useTranslations();
  const vaultType = VaultTypeNameEnum[VaultTypeEnum[type] as keyof typeof VaultTypeEnum];

  return (
    <SelectionModal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`shareEntityToEmployeeOrGroupSelectionModal.${vaultType}.dialogTitle`)}
    >
      <SelectionModalOption
        title={t(
          `shareEntityToEmployeeOrGroupSelectionModal.${vaultType}.employee.title`,
        )}
        description={t(
          `shareEntityToEmployeeOrGroupSelectionModal.${vaultType}.employee.description`,
        )}
        type={Ownership.Employees}
        onClick={onSelect}
      />
      <SelectionModalOption
        title={t(`shareEntityToEmployeeOrGroupSelectionModal.${vaultType}.group.title`)}
        description={t(
          `shareEntityToEmployeeOrGroupSelectionModal.${vaultType}.group.description`,
        )}
        type={Ownership.Groups}
        onClick={onSelect}
      />
    </SelectionModal>
  );
};

export default memo(ShareVaultsToEmployeeOrGroupSelectionModal);
