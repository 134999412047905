import { useCallback, useMemo, useState } from 'react';
import {
  Grid,
  Divider,
  PanelContent,
  SearchableTextField,
  FilterButton,
  Tooltip,
  useTranslations,
  usePopper,
  ActionButton,
  ShareIcon,
  ErrorIcon,
  DeleteIcon,
  useMapKeyValueExtractor,
  useSnackbar,
  PromptModal,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  encryptSymmetric,
  Button,
  PlusIcon, Box,
} from '@uniqkey-frontend/shared-app';
import { Ownership } from '@uniqkey-backend-organization-web/api-client';
import useOrganizationSecureNotesTable, {
  IOrganizationSecureNotesTableRow,
} from '../../../../hooks/tables/useOrganizationSecureNotesTable';
import OrganizationSecureNotesTable
  from '../../../../components/tables/OrganizationSecureNotesTable';
import OrganizationSecureNotesTabFilter, {
  IOrganizationSecureNotesTabFilterSubmitResult,
} from './components/OrganizationSecureNotesTabFilter';
import { TSelectionOptionType } from '../../../../components/SelectionModalOption';
import { useDeleteVaults, useDetachVaults } from '../../../../hooks/reactQuery';
import ShareVaultsToEmployeeModal from '../../../../components/ShareVaultsToEmployeeModal';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import ShareVaultsToEmployeeOrGroupSelectionModal
  from '../../../../components/ShareVaultsToEmployeeOrGroupSelectionModal';
import { logException } from '../../../../services/sentryService';
import CreateSecureNoteModal, {
  ICreateSecureNoteReturnValue,
} from '../../../../components/CreateSecureNoteModal';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import useVaultsAPI from '../../../../hooks/useVaultsAPI';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';

const OrganizationSecureNotesTab = () => {
  const [isShareSecureNotesModalOpen, setIsShareSecureNotesModalOpen] = useState<boolean>(false);
  const [shareSecureNotesModalType, setShareSecureNotesModalType] = useState<
    TSelectionOptionType | undefined
  >(undefined);
  const [isDetachSecureNotesModalOpen, setIsDetachSecureNotesModalOpen] = useState<boolean>(false);
  const [isDeleteSecureNotesModalOpen, setIsDeleteSecureNotesModalOpen] = useState<boolean>(false);
  const [isCreateSecureNoteModalOpen, setIsCreateSecureNoteModalOpen] = useState<boolean>(false);
  const [isCreateSecureNoteLoading, setIsCreateSecureNoteLoading] = useState<boolean>(false);

  const { t } = useTranslations();
  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const { showError, showSuccess, showWarning } = useSnackbar();
  const { symmetricKey } = useCompanionApplicationContext();
  const { createVaultSecureNote } = useVaultsAPI();
  const {
    selectedSecureNotes,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useOrganizationSecureNotesTable({
    noDataMessageKey: 'organizationSecureNotesTab.noData',
  });
  const {
    values: selectedSecureNotesAsObjects, keys: selectedSecureNotesIds,
  } = useMapKeyValueExtractor<IOrganizationSecureNotesTableRow>(selectedSecureNotes);
  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;
  const {
    mutate: mutateDetachSecureNote, isLoading: isDetachSecureNotesLoading,
  } = useDetachVaults();
  const {
    mutate: mutateDeleteSecureNote, isLoading: isDeleteSecureNotesLoading,
  } = useDeleteVaults();

  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(
    () => setIsFilterOpen(false),
    [setIsFilterOpen],
  );
  const handleDetachSecureNotesModalOpen = useCallback(
    () => setIsDetachSecureNotesModalOpen(true),
    [],
  );
  const handleDetachSecureNotesModalClose = useCallback(
    () => setIsDetachSecureNotesModalOpen(false),
    [],
  );
  const handleDeleteSecureNotesModalOpen = useCallback(
    () => setIsDeleteSecureNotesModalOpen(true),
    [],
  );
  const handleDeleteSecureNotesModalClose = useCallback(
    () => setIsDeleteSecureNotesModalOpen(false),
    [],
  );
  const handleShareSecureNotesModalTypeClose = useCallback(
    () => setShareSecureNotesModalType(undefined),
    [],
  );
  const handleShareSecureNotesModalClose = useCallback(
    () => setIsShareSecureNotesModalOpen(false),
    [],
  );
  const handleCreateSecureNoteModalOpen = useCallback(
    () => setIsCreateSecureNoteModalOpen(true),
    [],
  );
  const handleCreateSecureNoteModalClose = useCallback(
    () => setIsCreateSecureNoteModalOpen(false),
    [],
  );
  const handleShareSecureNotesModalTypeChange = useCallback((type: TSelectionOptionType) => {
    setShareSecureNotesModalType(type);
    handleShareSecureNotesModalClose();
  }, [handleShareSecureNotesModalClose]);
  const handleShareSecureNotesModalOpen = useCallback(
    () => (selectedSecureNotesAsObjects[0].ownership === Ownership.Groups
      ? handleShareSecureNotesModalTypeChange(Ownership.Groups)
      : setIsShareSecureNotesModalOpen(true)),
    [selectedSecureNotesAsObjects, handleShareSecureNotesModalTypeChange],
  );

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((
    updatedValues: IOrganizationSecureNotesTabFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const handleCreateSecureNote = useCallback(async (params: ICreateSecureNoteReturnValue) => {
    const { name, note } = params;
    setIsCreateSecureNoteLoading(true);
    try {
      await createVaultSecureNote({
        name,
        note: encryptSymmetric({
          key: symmetricKey,
          string: note,
          fallbackValue: null,
        }),
      });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.secureNote.created'),
        });
      } else {
        showWarning({
          text: t('createSecureNoteModal.approvalOnMobileToast'),
        });
      }
      handleCreateSecureNoteModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'OrganizationSecureNotesTab/handleCreateSecureNote exception',
      });
    } finally {
      setIsCreateSecureNoteLoading(false);
    }
  }, [
    createVaultSecureNote,
    handleCreateSecureNoteModalClose,
    symmetricKey,
    showError,
    showSuccess,
    showWarning,
    isTrustedPortalEnabled,
    t,
  ]);

  const handleDetachSecureNote = useCallback(async () => {
    mutateDetachSecureNote({
      vaultIds: selectedSecureNotesIds,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t(
              'organizationSecureNotesTab.detachSecureNoteModal.successMessage',
              { count: successCount },
            ),
          });
        }
        if (failCount) {
          showError({
            text: t('organizationSecureNotesTab.detachSecureNoteModal.errorMessage', {
              count: failCount,
            }),
          });
        }
        handleDetachSecureNotesModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t('common.somethingWentWrong') });
        logException(e, { message: 'OrganizationSecureNotesTab/handleDetachSecureNote exception' });
      },
    });
  }, [
    selectedSecureNotesIds,
    mutateDetachSecureNote,
    handleDetachSecureNotesModalClose,
    resetSelectedRows,
    resetActivePage,
    showSuccess,
    showError,
    t,
  ]);

  const handleDeleteSecureNote = useCallback(async () => {
    mutateDeleteSecureNote({
      vaultIds: selectedSecureNotesIds,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t(
              'organizationSecureNotesTab.deleteSecureNoteModal.successMessage',
              { count: successCount },
            ),
          });
        }
        if (failCount) {
          showError({
            text: t(
              'organizationSecureNotesTab.deleteSecureNoteModal.errorMessage',
              { count: failCount },
            ),
          });
        }
        handleDeleteSecureNotesModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t('common.somethingWentWrong') });
        logException(e, { message: 'OrganizationSecureNotesTab/handleDeleteSecureNote exception' });
      },
    });
  }, [
    selectedSecureNotesIds,
    mutateDeleteSecureNote,
    handleDeleteSecureNotesModalClose,
    resetSelectedRows,
    resetActivePage,
    showSuccess,
    showError,
    t,
  ]);

  const {
    isShareDisabled,
    isRemoveDisabled,
    isDeleteDisabled,
    shareTooltipTitle,
    removeTooltipTitle,
    deleteTooltipTitle,
  } = useMemo(() => {
    if (!selectedSecureNotesAsObjects.length) {
      return {
        isShareDisabled: true,
        isRemoveDisabled: true,
        isDeleteDisabled: true,
        shareTooltipTitle: t('common.tickRow'),
        removeTooltipTitle: t('common.tickRow'),
        deleteTooltipTitle: t('common.tickRow'),
      };
    }
    let disableShare = false;
    let disableRemove = false;
    let disableDelete = false;
    let shareTitle = t('organizationSecureNotesTab.share.active');
    let removeTitle = t('organizationSecureNotesTab.remove.active');
    let deleteTitle = t('organizationSecureNotesTab.delete.active');
    selectedSecureNotesAsObjects.forEach(({ ownership }) => {
      if (ownership === Ownership.Unmanaged) {
        disableRemove = true;
        removeTitle = t('organizationSecureNotesTab.remove.disabled');
      }
      if (ownership === Ownership.Employees) {
        disableShare = true;
        shareTitle = t('organizationSecureNotesTab.share.disabled');
        disableDelete = true;
        deleteTitle = t('organizationSecureNotesTab.delete.disabled');
      }
      if (ownership === Ownership.Groups) {
        disableDelete = true;
        deleteTitle = t('organizationSecureNotesTab.delete.disabled');
      }
    });
    const uniqueOwnershipCount = new Set(selectedSecureNotesAsObjects.map(
      ({ ownership }) => ownership,
    )).size;
    if (uniqueOwnershipCount > 1) {
      disableShare = true;
      disableRemove = true;
      disableDelete = true;
      shareTitle = t('common.valuesInManagedByShouldBeIdentical');
      removeTitle = t('common.valuesInManagedByShouldBeIdentical');
      deleteTitle = t('common.valuesInManagedByShouldBeIdentical');
    }
    return {
      isShareDisabled: disableShare,
      isRemoveDisabled: disableRemove,
      isDeleteDisabled: disableDelete,
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
      deleteTooltipTitle: deleteTitle,
    };
  }, [selectedSecureNotesAsObjects, t]);

  const ownership = useMemo(() => {
    if (selectedSecureNotesAsObjects.length) {
      return selectedSecureNotesAsObjects[0].ownership;
    }
    return null;
  }, [selectedSecureNotesAsObjects]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareSecureNotesModalOpen}
                disabled={isShareDisabled}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDetachSecureNotesModalOpen}
                disabled={isRemoveDisabled}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={deleteTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteSecureNotesModalOpen}
                disabled={isDeleteDisabled}
              >
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          <Box mr={3} />
          <Grid item>
            <Button
              icon={<PlusIcon />}
              onClick={handleCreateSecureNoteModalOpen}
            >
              {t('secureNotesTab.createSecureNoteButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <OrganizationSecureNotesTable
        selectedSecureNotes={selectedSecureNotes}
        {...restTableProps}
      />
      {isCreateSecureNoteModalOpen && (
        <CreateSecureNoteModal
          isOpen={isCreateSecureNoteModalOpen}
          onClose={handleCreateSecureNoteModalClose}
          onSubmit={handleCreateSecureNote}
          isLoading={isCreateSecureNoteLoading}
        />
      )}
      <OrganizationSecureNotesTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isShareSecureNotesModalOpen && (
        <ShareVaultsToEmployeeOrGroupSelectionModal
          isOpen={isShareSecureNotesModalOpen}
          onClose={handleShareSecureNotesModalClose}
          onSelect={handleShareSecureNotesModalTypeChange}
          type={VaultTypeEnum.SecureNote}
        />
      )}
      {shareSecureNotesModalType === Ownership.Employees && (
        <ShareVaultsToEmployeeModal
          isOpen={shareSecureNotesModalType === Ownership.Employees}
          onClose={handleShareSecureNotesModalTypeClose}
          vaults={selectedSecureNotesAsObjects}
          type={VaultTypeEnum.SecureNote}
          onSubmit={resetSelectedRows}
        />
      )}
      {shareSecureNotesModalType === Ownership.Groups && (
        <ShareVaultsToGroupModal
          isOpen={shareSecureNotesModalType === Ownership.Groups}
          onClose={handleShareSecureNotesModalTypeClose}
          vaults={selectedSecureNotesAsObjects}
          type={VaultTypeEnum.SecureNote}
          onSubmit={resetSelectedRows}
        />
      )}
      {isDetachSecureNotesModalOpen && (
        <PromptModal
          open={isDetachSecureNotesModalOpen}
          onClose={handleDetachSecureNotesModalClose}
          onSubmit={handleDetachSecureNote}
          title={t(
            `organizationSecureNotesTab.detachSecureNoteModal.detachFrom${ownership}.title`,
            { count: selectedSecureNotes.size },
          )}
          description={t(
            `organizationSecureNotesTab.detachSecureNoteModal.detachFrom${ownership}.description`,
          )}
          additionalDescription={t(
            // eslint-disable-next-line max-len
            `organizationSecureNotesTab.detachSecureNoteModal.detachFrom${ownership}.additionalDescription`,
          )}
          list={selectedSecureNotesAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDetachSecureNotesLoading}
        />
      )}
      {isDeleteSecureNotesModalOpen && (
        <PromptModal
          open={isDeleteSecureNotesModalOpen}
          onClose={handleDeleteSecureNotesModalClose}
          onSubmit={handleDeleteSecureNote}
          title={t(
            'organizationSecureNotesTab.deleteSecureNoteModal.title',
            { count: selectedSecureNotes.size },
          )}
          description={t(
            'organizationSecureNotesTab.deleteSecureNoteModal.description',
          )}
          approvalButtonText="common.delete"
          list={selectedSecureNotesAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteSecureNotesLoading}
        />
      )}
    </PanelContent>
  );
};

export default OrganizationSecureNotesTab;
