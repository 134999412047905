import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreateGroupRequest,
  CreateGroupResponse,
  GetGroupsResponse,
  GetGroupByIdResponse,
  NoContentResult,
  DeleteBulkGroupRequest,
  DeleteBulkGroupResponse,
  GetGroupsByVaultIdResponse,
  Operation,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { TGetGroupsMethod, TGetGroupsByVaultIdMethod } from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const useEmployeeGroupsAPI = () => {
  const { employeeGroupsAPIClient } = useApiClients();

  const createGroup = useCallback(
    (
      params: CreateGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<CreateGroupResponse> => employeeGroupsAPIClient
      .apiV1GroupsPost(params, options)
      .then(dataExtractor<CreateGroupResponse>()),
    [employeeGroupsAPIClient],
  );

  const getGroups = useCallback<TGetGroupsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        canManageVaults = undefined,
        employeeAccountId = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return employeeGroupsAPIClient.apiV1GroupsGet(
        page,
        pageLength,
        canManageVaults,
        employeeAccountId,
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetGroupsResponse>());
    },
    [employeeGroupsAPIClient],
  );

  const getGroupsByVaultId = useCallback<TGetGroupsByVaultIdMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        vaultId,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return employeeGroupsAPIClient.apiV1GroupsGetByVaultIdGet(
        page,
        pageLength,
        vaultId,
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetGroupsByVaultIdResponse>());
    },
    [employeeGroupsAPIClient],
  );

  const getGroupById = useCallback(
    (
      groupId: string,
      options?: AxiosRequestConfig,
    ): Promise<GetGroupByIdResponse> => employeeGroupsAPIClient
      .apiV1GroupsGroupIdGet(groupId, options)
      .then(dataExtractor<GetGroupByIdResponse>()),
    [employeeGroupsAPIClient],
  );

  const patchGroupById = useCallback(
    (groupId: string, operations: Array<Operation>, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => employeeGroupsAPIClient
      .apiV1GroupsGroupIdPatch(groupId, operations, options)
      .then(dataExtractor<NoContentResult>()),
    [employeeGroupsAPIClient],
  );

  const deleteGroups = useCallback(
    (
      deleteBulkGroupRequest: DeleteBulkGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<DeleteBulkGroupResponse> => employeeGroupsAPIClient
      .apiV1GroupsDeleteBulkPost(deleteBulkGroupRequest, options)
      .then(dataExtractor<DeleteBulkGroupResponse>()),
    [employeeGroupsAPIClient],
  );

  return ({
    createGroup,
    getGroups,
    getGroupsByVaultId,
    getGroupById,
    patchGroupById,
    deleteGroups,
  });
};

export default useEmployeeGroupsAPI;
