import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeByApplicationOrderPropertyName,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  IGetEmployeeAccountsByApplicationIdParams,
  TGetEmployeeAccountsByApplicationIdMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
import { IServiceEmployeesTableRow } from '../../../components/tables/ServiceEmployeesTable';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

export const REACT_QUERY_SERVICE_EMPLOYEES_KEY = [ReactQueryKeyEnum.ServiceEmployees];

interface IPersistentFilters extends Pick<
  IGetEmployeeAccountsByApplicationIdParams, 'applicationId'
> {}

export interface IUseServiceEmployeesTableParams {
  persistentFilters: IPersistentFilters;
}

export interface IUseServiceEmployeesTableReturn extends
  Omit<
    IUseTableReturn<IServiceEmployeesTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<
    IUseQueryTableFetchReturn<TGetEmployeeAccountsByApplicationIdMethod>,
    'data' | 'isFetchedAfterMount'
  >
{
  employees: IUseQueryTableFetchReturn<TGetEmployeeAccountsByApplicationIdMethod>['data'];
  selectedEmployees: IUseTableReturn<IServiceEmployeesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetEmployeeAccountsByApplicationIdParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<
    IGetEmployeeAccountsByApplicationIdParams['searchQuery']>
  >;
}

interface ITablePreservationConfigs extends
  Omit<IGetEmployeeAccountsByApplicationIdParams,
    'page' | 'pageLength' | 'applicationId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IServiceEmployeesTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetEmployeeAccountsByApplicationIdParams['page'];
  perPage: IGetEmployeeAccountsByApplicationIdParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: EmployeeByApplicationOrderPropertyName },
  ),
  columnOrderDirection: true,
};

const useServiceEmployeesTable = (
  params: IUseServiceEmployeesTableParams,
): IUseServiceEmployeesTableReturn => {
  const {
    persistentFilters,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getEmployeeAccountsByApplicationId } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetEmployeeAccountsByApplicationIdParams['searchQuery']
  >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedEmployees,
    resetTableMethods,
    ...restTableProps
  } = useTable<IServiceEmployeesTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetActivePage,
  } = resetTableMethods;

  const {
    data: employees, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_SERVICE_EMPLOYEES_KEY,
    queryClient,
    request: getEmployeeAccountsByApplicationId,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: EmployeeByApplicationOrderPropertyName[
        upperFirst(columnOrderBy) as keyof typeof EmployeeByApplicationOrderPropertyName
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    employees,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedEmployees,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps,
  };
};

export default useServiceEmployeesTable;
