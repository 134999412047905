import { memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTranslations,
  Grid,
  Button,
  DialogActions,
  emptyStringValidator,
  ReactHookFormTextField,
  buildInvalidFieldTranslation,
  Typography,
  TypographyWithTooltip,
  S5,
  S4,
} from '@uniqkey-frontend/shared-app';

export interface IEditGroupModalSubmitResult {
  name: string;
  description?: string | null;
  allowCustomName?: boolean;
}

interface IEditGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: IEditGroupModalSubmitResult) => Promise<void> | void;
  name?: string;
  description?: string | null;
  isLoading: boolean;
  initialName?: string | null;
  isScim?: boolean;
}

const EditGroupModal = (props: IEditGroupModalProps) => {
  const {
    isOpen, onClose, onSubmit, name, description, isLoading, initialName, isScim,
  } = props;
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<IEditGroupModalSubmitResult>(
    { defaultValues: { name, description }, mode: 'all' },
  );

  const [currentName] = watch('name');

  const disabled = !isDirty || !currentName;

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {isScim
            ? t('editGroupModal.editGroupDetails') : t('editGroupModal.title', { name })}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="name"
                control={control}
                fullWidth
                autoFocus
                error={!!errors.name}
                label={`${t('groupModal.groupName.label')}*`}
                placeholder={isScim
                  ? t('groupModal.groupName.enterNewName')
                  : t('groupModal.groupName.placeholder')}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string)
                      ? buildInvalidFieldTranslation('groupModal.groupName.label') : true
                  ),
                }}
              />
            </Grid>
            {!isScim && (
              <Grid item xs={12}>
                <ReactHookFormTextField
                  name="description"
                  control={control}
                  fullWidth
                  multiline
                  error={!!errors.description}
                  label={t('groupModal.description.label')}
                  placeholder={t('groupModal.description.placeholder')}
                  helperText={errors.description?.message}
                />
              </Grid>
            )}
            {isScim && (
              <>
                <Grid item xs={12}>
                  <Typography variant="caption" color={S5}>
                    {t('editGroupModal.initialName')}
                  </Typography>
                  <TypographyWithTooltip color={S4}>
                    {initialName}
                  </TypographyWithTooltip>
                </Grid>
                {!!description && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color={S5}>
                      {t('editGroupModal.description')}
                    </Typography>
                    <TypographyWithTooltip color={S4}>
                      {description}
                    </TypographyWithTooltip>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={disabled} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditGroupModal);
